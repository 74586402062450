import React from 'react';
import './footer.css';

const Footer = () => {
  return(
    <div className="footer">
      <div className='container-fluid'>
        <div id='footer' className="row" >
          <div className="col-md-12">
            <div className="footer-logo">
              <img src={require("./../../assets/images/logo-icons/logo-name.png")} alt="Proxfit logo"/>
            </div>
            <div className="footer-allrights">
              <a href="https://www.mhtas.com/" target="_blank" rel="noopener noreferrer" className="abalda_link">www.mhtas.com</a>
              <p>@ MARREN 2023. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;