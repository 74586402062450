import React, { Component } from 'react';
import './home.css';
import ReactWOW from 'react-wow';

class Business extends Component {
  constructor(props) {
    super(props);
    this.state ={
      selectedStep: "one",
      imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635095/proxfitWeb/proxfitImages/businessSection/img_one.png",
      opacity: 1
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.props.isVisible && this.props.history.location.hash !== "#business") {
      this.props.history.replace("#business")
    } 
  };
  
  selectedTabHandler = (e) => {
    if (this.state.selectedStep === e.currentTarget.id ) {
      return
    } else
    if (e.currentTarget.id === "one") {
      this.setState({
        opacity: 0,
        selectedStep: "one"
      }, () => setTimeout(() => 
        this.setState({opacity:1, imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635095/proxfitWeb/proxfitImages/businessSection/img_one.png"}),500))
    } else if (e.currentTarget.id === "two") {
        this.setState({
          opacity: 0,
          selectedStep: "two"
        }, () => setTimeout(() => 
          this.setState({opacity:1, imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635095/proxfitWeb/proxfitImages/businessSection/img_two.png"}),500))
    } else {
      this.setState({
        opacity: 0,
        selectedStep: "three"
      }, () => setTimeout(() => 
        this.setState({opacity:1, imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635095/proxfitWeb/proxfitImages/businessSection/img_three.png"}),500))
    }
  }

  render() {
    return(
      <section id="business" className='page_section'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                <div className="title-area">
                  <p className="subtitle">Business</p>
                  <h2 className="title">Monetize through Proxfit Vouchers in three easy steps	</h2>
                </div>
              </ReactWOW>
            </div>
            <div className="col-md-12">
              <div className="vouchers-content">
                <div className="row">
                  <div className="col-lg-6 col-md-12">   
                    <ReactWOW animation='fadeInUp' duration='0.5s' delay='0.2s'>
                      <div className="screens_container">
                        <img  id="vocuher_img" src={this.state.imgSrc} alt='Proxfit Voucher Screen' style={{opacity: this.state.opacity, transition: "opacity 500ms"}}/>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-lg-6 col-md-12">                    
                      <ReactWOW animation='fadeInUp' duration='0.5s' delay='0.2s'>
                        <div className="one_step">
                          <div id="one" className="one_step_top" onClick={this.selectedTabHandler}>
                            <h5>1. Voucher Creation</h5>
                            <button className={this.state.selectedStep === "one" ? "arrow_button btn_collapse" : "arrow_button btn_expand"}></button>
                          </div>
                          <p className={this.state.selectedStep === "one" ? "one_step_middle show_text" : "one_step_middle hide_text"}>Create a voucher through setting the appropriate media, description, title, price and discount where applicable.</p>
                          <hr className="one_step_bottom"/>
                        </div>
                      </ReactWOW>
                      <ReactWOW animation='fadeInUp' duration='0.5s' delay='0.2s'>
                        <div className="one_step">
                          <div id="two" className="one_step_top" onClick={this.selectedTabHandler}>
                            <h5>2. Voucher Publishing</h5>
                            <button className={this.state.selectedStep === "two" ? "arrow_button btn_collapse" : "arrow_button btn_expand"}></button>
                          </div>
                          <p className={this.state.selectedStep === "two" ? "one_step_middle show_text" : "one_step_middle hide_text"}>Publish, keep track and maintain your vouchers easily.</p>
                          <hr className="one_step_bottom"/>
                        </div>
                      </ReactWOW>
                      <ReactWOW animation='fadeInUp' duration='0.5s' delay='0.2s'>
                        <div className="one_step">
                          <div id="three"  className="one_step_top" onClick={this.selectedTabHandler}>
                            <h5>3. Voucher Monetization</h5>
                            <button className={this.state.selectedStep === "three" ? "arrow_button btn_collapse" : "arrow_button btn_expand"}></button>
                          </div>
                          <p className={this.state.selectedStep === "three" ? "one_step_middle show_text" : "one_step_middle hide_text"}>Scan vouchers upon contact with clients and get paid directly to your bank account on a fortnightly basis.</p>
                          <hr className="one_step_bottom"/>
                        </div>
                      </ReactWOW>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Business;