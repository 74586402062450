import React, { Component } from 'react';
import './home.css';
import API from '../../API.js';

class Enquiries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      email: "",
      phoneNum: "",
      message: "",
      emailOne: 'mymail@email.com',
      contactOne: "",
      warnigMessage: "",
      redWarningLetters: false,
      loaderSendingMsgVisibe: false, 
      messageInRed: true,
      messageInGreen: false
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.props.isVisible && this.props.history.location.hash !== "#enquiries") {
      this.props.history.replace("#enquiries")      
    } 

  };

  onChangeInputHandler = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  validateName = (fullName) => {
    if(fullName.length <= 5) {
      return false
    } else {
      return true
    }
  }  

  validateEmail = (email) => {
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  handleSubmitEnquirie = (e) => {
    this.setState({warnigMessage: ""})
    e.preventDefault();
    if (!this.state.fullName || !this.state.email || !this.state.message) {
      this.setState({redWarningLetters: true, warnigMessage: "All fields with an *  must be filled out."})
      return
    } else if (!this.validateName(this.state.fullName)) {
      this.setState({warnigMessage: "Full Name must be at least 6 characters long."})
      return
    } else if (!this.validateEmail(this.state.email)) {
    this.setState({warnigMessage: "Please enter a valid email format."})
      return
    }
    this.setState({warnigMessage: "", loaderSendingMsgVisibe: true})

    const emailObj = {
      name: this.state.fullName,
      email: this.state.email,
      message: this.state.message,
      contact_one: this.state.contactOne,
      email_one: this.state.emailOne,
      phone: this.state.phoneNum
    }

    API.sendEnquirieEmail(emailObj) 
    .then(res => {
        this.setState({
          messageInRed: false,
          messageInGreen: true,
          loaderSendingMsgVisibe: false, 
          warnigMessage: "Thank you! Your message has been sent, we will contact you soon!",
          fullName: "",
          email: "",
          phoneNum: "",
          message: "",
          redWarningLetters: false
        }, () => setTimeout(() => 
        this.setState({messageInGreen: false, messageInRed: true, warnigMessage: ''}), 5000));
    })
    .catch(error => {
      this.setState({messageInRed: true, loaderSendingMsgVisibe: false, warnigMessage: 'Oops! An error occured and your message could not be sent.'})
    });
  }

  render() {
    return(
      <section id="enquiries" className='page_section'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-area">
                  <h2 className="title">Commercial Enquiries</h2>
                  <p>Please email us for details about notification campaigns, sponsored posts and splash screens in the Proxfit App.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="contact-area">
                <form id="enq-ajax-contact" >
                  <div className='input-wrap'>
                    <input id="enq-name" type="text" className={!this.state.redWarningLetters ? "custom-input" : "custom-input fill-fields"} placeholder="Full Name *" 
                     name="fullName" value={this.state.fullName} onChange={this.onChangeInputHandler}></input>
                  </div>
                  <div className='input-wrap'>
                    <input id="enq-email" type="email" className={!this.state.redWarningLetters ? "custom-input" : "custom-input fill-fields"} placeholder="Email Address *" 
                     name="email" value={this.state.email} onChange={this.onChangeInputHandler}></input>                 
                  </div>
                  <div className='input-wrap'>
                    <input id="enq-phone" type="tel" className="custom-input" placeholder="Phone Number"
                     name="phoneNum" value={this.state.phoneNum} onChange={this.onChangeInputHandler}></input>                 
                  </div>
                  <div className='input-wrap'>    
                    <textarea id="enq-message" type="textarea"className={!this.state.redWarningLetters ? "custom-textarea" : "custom-textarea fill-fields"} rows="3" placeholder="Message *" 
                     name="message" value={this.state.message} onChange={this.onChangeInputHandler}></textarea>             
                  </div>
                  <div className="input-field">
                    <input type="text" name="contact" defaultValue={this.state.contactOne} />
                  </div>
                  <div className="text-field">
                    <input type="text" name="email" defaultValue={this.state.emailOne} />
                  </div>
                  <div className="send-area">
                    <button id="send-enquirie" className="send-btn" type="submit" onClick={this.handleSubmitEnquirie} disabled={this.state.loaderSendingMsgVisibe ? true : false}>
                      <span className={this.state.loaderSendingMsgVisibe ? "hidden" : ""}>Send</span>
                    </button>
                    <div className={this.state.loaderSendingMsgVisibe ? "loader_wrap": "hidden"} >
                      <div className="start-loader"></div>
                    </div>
                  </div> 
                </form>
                <div className={this.state.messageInGreen ? "form-messages success" : (this.state.messageInRed ? "form-messages error" : "form-messages") }>{this.state.warnigMessage}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Enquiries;