import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from './header/HeaderComponent';
import HomePage from './HomePage';
import Support from './supportPage/SupportPage';
import Legal from './LegalPage';
import Privacy from './PrivacyPage';
import ButtonUp from './buttonUp/buttonUp';
import Footer from './footer/FooterComponent';
import './main.css';


const Main = () => {

  return(
    <div>
      <Header/>
        <div className='main-container'>
          <Switch>
            <Route path='/home' component={HomePage} />
            <Route exact path='/support' component={Support} />
            <Route exact path='/legal' component={Legal} />
            <Route exact path='/privacy' component={Privacy} />
            <Redirect to="/home" />
          </Switch>
          <ButtonUp/>
        </div>
      <Footer/>
    </div>
  )

}

export default Main;