import axios from 'axios';
import { serverURL } from './config';

axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
     return Promise.reject(error.response.data.error);
  }
);

const sendEnquirieEmail = (emailObj) => {
  return axios.post(`${serverURL}/sendEnquirieEmail`, emailObj)
}

const sendSupportEmail = (emailObj) => {
  return axios.post(`${serverURL}/sendSupportEmail`, emailObj)
}

export default {
  sendEnquirieEmail,
  sendSupportEmail
}
