import React, { Component } from 'react';
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { HashLink as NavLink  } from 'react-router-hash-link';
import windowSize from 'react-window-size';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import './header.css';
import { animateScroll as scroll } from 'react-scroll';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false,
      navBckgrIsGreen: true,
      selectedTab: this.props.location.hash ? this.props.location.hash.substr(1) : (this.props.location.pathname === '/' ? "home" : this.props.location.pathname.substr(1)),
      navBckgrIsTransparent: this.props.location.pathname === "/" ? true :( this.props.location.hash === "#home" ? true : false),
      prevScrollpos: window.pageYOffset
    };

  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("popstate", this.handeURLChange)

    this.targetElement = document.querySelector('#navigation');
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("popstate", this.handeURLChange)

    clearAllBodyScrollLocks();
  }
 

  handeURLChange = () => {
    this.setState({
      selectedTab: this.props.location.hash ? this.props.location.hash.substr(1) : (this.props.location.pathname === '/' ? "home" : this.props.location.pathname.substr(1)),
      navBckgrIsTransparent: this.props.location.pathname === "/" ? true :( this.props.location.hash === "#home" ? true : false)
    })
  }

  navigationHandler = (e) => {
    let clicked = e.target.name
    this.setState({selectedTab: clicked}, () => this.scrollToTopHandler())
    this.backgroundTransparencyHandler(clicked)
    if (this.props.windowWidth >= 992) {
      return
    } else {
      this.toggleNav()
    }
  }

  toggleNav = () => {
    if (this.state.navBckgrIsTransparent) { 
      this.setState({
        navBckgrIsTransparent: false,
        isNavOpen: !this.state.isNavOpen,
        navBckgrIsGreen: !this.state.navBckgrIsGreen
      }, () => {
        if(this.state.isNavOpen) {
          disableBodyScroll(this.targetElement);
        } else {enableBodyScroll(this.targetElement);}
        }
      );
    } else {
      this.setState({
        isNavOpen: !this.state.isNavOpen,
        navBckgrIsGreen: !this.state.navBckgrIsGreen
      }, () => {
        if(this.state.isNavOpen) {
          disableBodyScroll(this.targetElement);
        } else {enableBodyScroll(this.targetElement);}
        }
      );
    }
  }

  backgroundTransparencyHandler = (clicked) => {
    if(clicked === 'home') {
      this.setState({
        navBckgrIsTransparent: true
      })
    } else {
      this.setState({
        navBckgrIsTransparent: false
      })
    }
  }

  handleScroll = () => {
    if (this.state.selectedTab === "support" || this.state.selectedTab === "legal") {
      return
    } else if (window.pageYOffset >=100) {
      this.setState({
        navBckgrIsTransparent: false,
        selectedTab: this.props.location.hash.substr(1)
      });
    } else {
      this.setState({
        navBckgrIsTransparent: true,
        selectedTab: this.props.location.hash.substr(1)
      });
    }
  };

  scrollToTopHandler = () => {
    if (this.state.selectedTab === "support" || this.state.selectedTab === "legal") {
      scroll.scrollToTop({
        duration: 0,
        delay: 0
      });
   } 
  }

  render() {
    return (
      <div id="navigation" className={this.state.navBckgrIsTransparent ? "nav-area nav-custom-transparent" : (this.state.navBckgrIsGreen ? "nav-area" : "nav-area nav-custom-white-bckgr")}>
        <div className="container">
          <Navbar  dark expand="lg">
            <NavbarBrand className={this.state.navBckgrIsGreen ? "" : "hide-nav-brand"} href="#"><img src={require('./../../assets/images/logo-icons/logoAndproxfit.png')} alt='Proxfit logo' /></NavbarBrand>
            <NavbarToggler className={this.state.navBckgrIsGreen ? "ml-auto" : "ml-auto close"} onClick={this.toggleNav}>
              <div className='button_toggle_line'></div>
              <div className='button_toggle_line'></div>
              <div className='button_toggle_line'></div>
            </NavbarToggler>         
            <Collapse isOpen={this.state.isNavOpen} navbar>
              <Nav navbar className='ml-auto'>
                <NavItem>
                    <NavLink name='home' className={(this.state.navBckgrIsGreen ? (this.state.selectedTab === "home" ? "nav-link selected-link" : "nav-link") : 
                      (this.state.selectedTab === "home" ? "nav-link selected-link2" : "nav-link black-text"))} 
                      onClick={this.navigationHandler} smooth to='/home#home' > Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink name='features' className={(this.state.navBckgrIsGreen ? (this.state.selectedTab === "features" ? "nav-link selected-link" : "nav-link") : 
                      (this.state.selectedTab === "features" ? "nav-link selected-link2" : "nav-link black-text"))} 
                      onClick={this.navigationHandler} smooth  to='/home#features'> Features</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink name='about' className={(this.state.navBckgrIsGreen ? (this.state.selectedTab === "about" ? "nav-link selected-link" : "nav-link") : 
                      (this.state.selectedTab === "about" ? "nav-link selected-link2" : "nav-link black-text"))} 
                      onClick={this.navigationHandler} smooth  to='/home#about'> About</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink name='business' className={(this.state.navBckgrIsGreen ? (this.state.selectedTab === "business" ? "nav-link selected-link" : "nav-link") : 
                      (this.state.selectedTab === "business" ? "nav-link selected-link2" : "nav-link black-text"))} 
                      onClick={this.navigationHandler} smooth  to='/home#business'> Business</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink name='enquiries' className={(this.state.navBckgrIsGreen ? (this.state.selectedTab === "enquiries" ? "nav-link selected-link" : "nav-link") : 
                      (this.state.selectedTab === "enquiries" ? "nav-link selected-link2" : "nav-link black-text"))}  
                      onClick={this.navigationHandler} smooth  to='/home#enquiries'> Enquiries</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink name='support' className={(this.state.navBckgrIsGreen ? (this.state.selectedTab === "support" ? "nav-link selected-link" : "nav-link") : 
                      (this.state.selectedTab === "support" ? "nav-link selected-link2" : "nav-link black-text"))}  
                      onClick={this.navigationHandler}  to='/support'>Help</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink name='legal' className={(this.state.navBckgrIsGreen ? (this.state.selectedTab === "legal" ? "nav-link selected-link" : "nav-link") : 
                      (this.state.selectedTab === "legal" ? "nav-link selected-link2" : "nav-link black-text"))}  
                      onClick={this.navigationHandler}   to='/legal'>Legal</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    )
  };
};

export default withRouter(windowSize(Header));