import React, { Component } from 'react';
import './home.css';

class Video extends Component {

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.props.isVisible && this.props.history.location.hash !== "#about") {
      this.props.history.replace("#about")
    }
  };

  render() {
    return(
      <section id="about" className='page_section'>
        <video id="video_proxfit" controls poster="https://res.cloudinary.com/ds7i0533q/image/upload/v1580373663/proxfitWeb/proxfitImages/z0uk06dmkxytno0j3khv.png" loop>
          <source src="https://res.cloudinary.com/ds7i0533q/video/upload/v1581938582/proxfitWeb/proxfitVideo/Proxfit-Promo-Video.mp4" type="video/mp4"/>
          Your browser does not support the video tag.
        </video>         
      </section>
    );
  }
}

export default Video;