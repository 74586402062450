import React, { Component } from 'react';
import './home.css';
import ReactWOW from 'react-wow';

class Features extends Component {

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.props.isVisible && this.props.history.location.hash !== "#features") {
      this.props.history.replace("#features")
    } 
  };

  render() {
    return(
      <section id="features" className='page_section'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-area">
                <p className="subtitle">Features</p>
                <h2 className="title">Dual Tier<span> Application</span></h2>
              </div>
            </div>
            <div className="col-md-12">
              <div className="features-area">
                <div className="row features-row">
                  <div className="col-lg-4">
                    <div className="features-left">
                      <ul className="features-list">
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li className="account-type-left">
                              <h3>User Account<br/> Features</h3>
                          </li>
                        </ReactWOW>
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li>
                            <div className="features-content">
                              <h4>Find</h4>
                              <p><span></span>The best services and products in your proximity</p>
                            </div>
                            <div className="fas">
                              <img  src={require("./../../assets/images/features-icons/search.png")} alt="search icon"/>
                            </div>
                          </li>
                        </ReactWOW>
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li>
                            <div className="features-content">
                              <h4>Share</h4>
                              <p>Your moments, experiences, thoughts and lifestyle with others</p>
                            </div>
                            <div className="fas">
                                <img  src={require("./../../assets/images/features-icons/share.png")} alt="share icon"/>
                            </div>
                          </li>
                        </ReactWOW>
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li>
                            <div className="features-content">
                              <h4>Get </h4>
                              <p>Special offers from service providers like discounts for training, massages...</p>
                            </div>
                            <div className="fas">
                                <img  src={require("./../../assets/images/features-icons/percent.png")} alt="percent icon"/>
                            </div>
                          </li>
                        </ReactWOW>
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li>
                            <div className="features-content">
                              <h4>Easy to Use</h4>
                              <p>Intuitive simplistic design built around your needs</p>
                            </div>
                            <div className="fas">
                                <img  src={require("./../../assets/images/features-icons/pointer.png")} alt="pointer icon"/>
                            </div>
                          </li>
                        </ReactWOW>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <ReactWOW animation='fadeInUp' duration='0.5s' delay='0.2s'>
                      <div className="feature-img">
                        <img src="https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635070/proxfitWeb/proxfitImages/featuresSection/wjqyxzsqqglvxh6iokku.png" alt="Proxfit Splash Screen"/>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-lg-4">
                    <div className="features-right">
                      <ul className="features-list">
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li className="account-type-right">
                              <h3>Business Account<br/> Features</h3>
                          </li>
                        </ReactWOW>
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li>
                            <div className="fas">
                                <img  src={require("./../../assets/images/features-icons/map.png")} alt="map icon"/>
                            </div>
                            <div className="features-content">
                              <h4>Let </h4>
                              <p>Clients get to know more about you, your products and services</p>
                            </div>
                          </li>
                        </ReactWOW>
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li>
                            <div className="fas">
                                <img  src={require("./../../assets/images/features-icons/megaphone.png")} alt="megaphone icon"/>
                            </div>
                            <div className="features-content">
                              <h4>Promote</h4>
                              <p>Be noticed on the high concentration network through promotions</p>
                            </div>
                          </li>
                        </ReactWOW>
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li>
                            <div className="fas">
                                <img  src={require("./../../assets/images/features-icons/file.png")} alt="file icon"/>
                            </div>
                            <div className="features-content">
                              <h4>Share</h4>
                              <p>Your latest through Posts, Media and Vouchers</p>
                            </div>
                          </li>
                        </ReactWOW>
                        <ReactWOW animation='fadeInLeft' duration='0.5s' delay='0.2s'>
                          <li>
                            <div className="fas">
                                <img  src={require("./../../assets/images/features-icons/voucher.png" )}alt="voucher icon"/>
                            </div>
                            <div className="features-content">
                              <h4>Vouchers</h4>
                              <p>Sell your services and products through digital vouchers </p>
                            </div>
                          </li>
                        </ReactWOW>
                      </ul>
                    </div>
                  </div>   
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
};

export default Features;