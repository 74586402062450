import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import './buttonUp.css';

class ButtonUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      buttonUpVisible: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset ;
    const visible = this.state.prevScrollpos < currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      buttonUpVisible: visible
    });
  }; 

  scrollToTop() {
    scroll.scrollToTop();
  }

  render() {
    return(
      <button className={this.state.buttonUpVisible ? 'to-the-top-btn' : 'hide to-the-top-btn'} onClick={this.scrollToTop}>
        <img src={require('./../../assets/images/icons/arrow-up.png')} alt='arrow icon'/>
      </button>
    )
  }
}

export default ButtonUp;