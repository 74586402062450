import React, { Component } from 'react';
import WikiSupportFeatures from './WikiSupport';
import './support.css';
import { scroller } from 'react-scroll';
import ContactSupport from './ContactSupport';
import Loader from './../louder/louder';
import windowSize from 'react-window-size';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class Support extends Component {

  constructor(props) {
    super(props);

    this.state = {
      supportFeatures: WikiSupportFeatures,
      dropdownOpen: false,
      clickedLink: "",
      preloaderIsVisible: true
    };
  }

  componentDidMount() {
    this.timer = setTimeout(
      () => this.setState({preloaderIsVisible: false}),
      600,
    );
    this.targetElement = document.querySelector('#dropdown_menu');
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearAllBodyScrollLocks();
    document.removeEventListener('click', this.dropdownClose);
  }

  toggleDropdownHandler = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    }, () => {
      if (this.state.dropdownOpen) {
        document.addEventListener('click', this.dropdownClose);      
    } else if (this.props.windowWidth < 577) {
        if (this.state.dropdownOpen) {
          disableBodyScroll(this.targetElement);
        } else {
          enableBodyScroll(this.targetElement);
        }
      }
    });
  }

  dropdownClose = () => {
    this.setState({ dropdownOpen: false });
    document.removeEventListener('click', this.dropdownClose);
  }

  scrollTo(event) {
    enableBodyScroll(this.targetElement);
    this.setState({
      clickedLink: event.target.name,
      dropdownOpen: false
    }, () => {
      let element = this.state.clickedLink;
      console.log(element)
      scroller.scrollTo(element, {
        duration: 1000,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -100
      })
    })
  }

  render() {
    return(
      <React.Fragment>
        <Loader visibility={this.state.preloaderIsVisible}/>
        <section id="support" className='page_section'>
          <div className="support_FAQ">
            <div className='container'>
              <div className='row'>
                <div className="col-md-12">
                  <div className="title-area">
                    <p className="subtitle">Support</p>
                    <h2 className="title">FAQ and Support Center</h2>
                    <div className='content-menu'>
                      <button onClick={this.toggleDropdownHandler} className="dropdown-button">
                        Content<div className="arrow-down"></div>
                      </button>
                      <div id='dropdown_menu' className={this.state.dropdownOpen ? 'custom-dropdown-menu' : 'custom-dropdown-menu menu-hidden'}>
                        <ul>
                          <li><button onClick={(e) => this.scrollTo(e)} name="creating-account">Creating Account</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="creating-post">Creating Post</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="likes-comments">Likes and Comments</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="finding-services">Find a Service provider</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="edit-profile">Edit your Profile</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="change-phone-number">Change Phone Number</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="change-password">Change Password</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="notifications-settings">Notifications Settings</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="create-voucher">Voucher Creation</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="buy-voucher">Buying Voucher</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="use-voucher">Using Voucher</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="voucher-gift">Voucher Gift</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="scan-voucher">Scanning Voucher</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="chat">Chat</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="review-service">Reviewing Services</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="payment-cycle">Payment Cycles</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="sponsored-posts">Sponsored Posts</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="campaign">Ads</button></li>
                          <li><button onClick={(e) => this.scrollTo(e)} name="contact-support">CONTACT SUPPORT</button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  {this.state.supportFeatures.map((feature) => {
                    return (
                      <div key={feature.featureId}>
                        <h5 id={feature.heading ? feature.name : ""} className="support_heading">{feature.heading}</h5>
                        <p className="support_text">{feature.text}</p>
                        <div  className="support_images_wrap">
                          {feature.images.map((image) => {
                            return(
                              <div key={image.imgID} className="support_image_container">
                                <img src={image.imgSrc} alt={image.imgAlt}/>
                              </div>
                            )}
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactSupport/>
      </React.Fragment>
    )
  }
}

export default windowSize(Support);