import React, { Component } from 'react';
import Loader from './louder/louder';
import TrackVisibility from 'react-on-screen';
import Home from './homePageSections/HomeSection'
import Features from './homePageSections/FeaturesSection';
import Video from './homePageSections/VideoSection';
import Business from './homePageSections/BusinessSection';
import Enquiries from './homePageSections/EnquiriesSection';
import windowSize from 'react-window-size';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preloaderIsVisible: true
    }

  }

  componentDidMount() {
    this.timer = setTimeout(
      () => this.setState({preloaderIsVisible: false}),
      600,
    );
    if(this.props.windowWidth >= 992) {
      this.setState({offset: -800})
    } else if (this.props.windowWidth < 991 && this.props.windowWidth >= 768) {
       this.setState({offset: -700})
    } else if (this.props.windowWidth < 767 && this.props.windowWidth >= 577) {
      this.setState({offset: -600})
    } else if (this.props.windowWidth < 576 && this.props.windowWidth >= 501) {
      this.setState({offset: -500})
    } else if (this.props.windowWidth < 500 && this.props.windowWidth >= 391) {
      this.setState({offset: -400})
    } else if (this.props.windowWidth < 390) {
      this.setState({offset: -300})
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render(){
    return(
      <div>
        <Loader visibility={this.state.preloaderIsVisible}/>
        <TrackVisibility partialVisibility throttleInterval={1} offset={this.state.offset}>
          <Home isVisible history={this.props.history} />
        </TrackVisibility>
        <TrackVisibility partialVisibility throttleInterval={1} offset={this.state.offset}>
          <Features isVisible history={this.props.history}/>
        </TrackVisibility>
        <TrackVisibility partialVisibility throttleInterval={1} offset={this.state.offset}>
          <Video isVisible history={this.props.history}/>
        </TrackVisibility>
        <TrackVisibility partialVisibility throttleInterval={1} offset={this.state.offset}>
          <Business isVisible history={this.props.history} />
        </TrackVisibility>
        <TrackVisibility partialVisibility throttleInterval={1} offset={this.state.offset}>
          <Enquiries isVisible history={this.props.history}/>
        </TrackVisibility>
      </div>
    )
  }
}

export default windowSize(HomePage);