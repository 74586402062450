import React, { Component } from 'react';
import './supportPage/support.css';
import Loader from './louder/louder';

class Legal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preloaderIsVisible: true
    }

  }

  componentDidMount() {
    this.timer = setTimeout(
      () => this.setState({preloaderIsVisible: false}),
      600,
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return(
      <React.Fragment>
        <Loader visibility={this.state.preloaderIsVisible}/>
        <section id="legal" className='page_section'>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-area second-title">
                    <h2 className="title">PRIVACY POLICY</h2>
                </div>
              </div>
              <div className="col-md-12">
                <p className="support_text details_paragraph">PLEASE READ THIS PRIVACY POLICY CAREFULLY.</p>
              </div>
              <div className="col-md-12">
                <p className="support_text">This Privacy Policy is applicable to MARREN MARKETING WLL at Saudi Arabia. (MARREN") and sets out our policy on the gathering and use of information on Proxfit App (as defined in the Terms of Use). The MARREN is committed to providing safe Proxfit App for visitors and has implemented this Privacy Policy to demonstrate MARREN commitment to your privacy. </p>
                <p className="support_text">This Privacy Policy applies only to Proxfit App and not to mobile applications and websites of other companies or organizations (Linked Sites) to which Proxfit App may be linked. You must check on any Linked Sites for the privacy policy that applies to that Linked Site. MARREN does not endorse, and is not responsible for, these Linked Sites.</p>
                <p className="support_text">When you install, subscribe or sign-up to access Proxfit App, you may be asked to provide us with personal information when you visit certain sections of Proxfit App. Your use of Proxfit App signifies your acknowledgement and consent to our Privacy Policy. If you do not agree to this Privacy Policy, please do not continue to use Proxfit App.</p>
    
                <h5 className="support_heading">Collection of Personal Information</h5>
                <p className="support_text">Personal Information is information about you that identifies you as an individual, for example, your name, address, e-mail address, or telephone number.</p>
                <p className="support_text">We collect information that you voluntarily provide to us through responses to surveys, search functions, questionnaires, feedback, Tell Your Story forms and the like. We may also ask you to provide additional information if you want to obtain additional services, information, participate in a contest or to resolve complaints or concerns.</p>
    
                <h5 className="support_heading">How Does MARREN Use Your Personal Information?</h5>
                <p className="support_text">Before forwarding us any personal information, please be advised that any information gathered may be used in the aggregate for research and development relating to Proxfit App and/or for future mobile application development. We may also use information gathered about you for the following purposes: to monitor interest in our range of products and to assist us to tailor the Content of Proxfit App to your needs by collecting information about your preferences through tracking of patterns page views on Proxfit App; to create a profile relating to you in order to show you the content that might be of interest to you and to display the Content according to your preferences; and, in circumstances where you have indicated that you wish to receive additional information, to send you information about us and promotional material about our products together with details of any offers we may have available from time to time. </p>
                
                <h5 className="support_heading">Promotional and Informational Offers</h5>
                <p className="support_text">With your permission at the time of registration or submission, your information may be used by us for marketing and promotional purposes. If you object to such use for any reason, you may prevent that use, either by email request or by modifying the registration information provided. MARREN uses reasonable efforts to maintain Users' information in a secure environment. If you have submitted personal information and want to change it or opt-out, please email us on contact@marren.com.</p>
                
                <h5 className="support_heading">Disclosure of Information</h5>
                <p className="support_text">The MARREN will not disclose your personal information that you provide on Proxfit App except in accordance with the terms of Use, this Privacy Policy and the “DISCLOSURE” annexed to it. </p>
    
                <h5 className="support_heading">Cookies</h5>
                <p className="support_text">MARREN, in common with many mobile application and web site operators, may use standard technology called "cookies" on its Proxfit Website. Cookies are small data files that are downloaded onto your computer when you visit Proxfit Website. You can disable cookies by turning them off in your browser; however, some areas of Proxfit Website may not function properly if you do so.</p>
              </div>
              <div className="col-md-12">
                <div className="title-area second-title">
                    <h2 className="title">DISCLOSURE</h2>
                </div>
              </div>
              <div className="col-md-12">
                <h5 className="support_heading">Disclosure of Personal Information</h5>
                <p className="support_text">You hereby acknowledge and confirm that MARREN may collect, use and disclose or transfer your personal information as permitted by the applicable laws.</p>
                <p className="support_text">Without prejudicing the generality of the foregoing, you acknowledge and confirm that MARREN may disclose you personal information under the following circumstances;</p>
                <p className="support_text indent_paragraph">- to Canadian and other governmental competent authorities as required, if you are reporting an adverse event or side effect;</p>
                <p className="support_text indent_paragraph">- to our subsidiaries, affiliates, agents and outside service providers, whom we require to assist us in carrying out our business and who have agreed to protect the confidentiality of your personal information on terms that are consistent with those set out in our Privacy Policy. Only personal information that is required to provide the service in question will be disclosed or transferred;</p>
                <p className="support_text indent_paragraph">- to a court, administrative tribunal, governmental authority or other body authorized to compel the disclosure of your personal information, for the purpose of complying with legal requirements such as a law, regulation, search warrant, subpoena, or court or administrative order, or as otherwise required or permitted by applicable law;</p>
                <p className="support_text indent_paragraph">- if the rights or property of MARREN, its subsidiaries, affiliates, third party service providers or the users of our website may be compromised;</p>
                <p className="support_text indent_paragraph">- if the personal safety of users of our website or other members of the public may be compromised; and</p>
                <p className="support_text indent_paragraph">- in connection with a proposed or actual financing, securitization, sale, merger, amalgamation, plan of arrangement, acquisition, conveyance, assignment or other transfer or disposal of all or part of MARREN or our business or assets, for the purpose of evaluating and/or carrying out the proposed transaction, and may use and disclose your personal information for the same purposes for which the information was collected and on terms of confidentiality that are consistent with those set out in our Privacy Policy. Only personal information that is required for these purposes will be disclosed or transferred.</p>
                <p className="support_text">Please note that as any of these disclosures may involve the storage or processing of personal information outside of Saudi Arabia and may therefore be subject to different privacy laws than those applicable in Saudi Arabia, including laws that require the disclosure of personal information under circumstances that are different than those that apply in Saudi Arabia.</p>
    
                <h5 className="support_heading">Protection of Information</h5>
                <p className="support_text">We have put in place physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access, maintain data security, and correctly use the information we collect online. MARREN applies security safeguards appropriate to the sensitivity of the information, such as retaining information in secure facilities and making personal information accessible only to authorized employees on a need-to-know basis.</p>
                
                <h5 className="support_heading">Storage of Information</h5>
                <p className="support_text">Personal information you share with us is stored on our database servers at MARREN data centers (in whatever country they may be located) or hosted by third parties who have entered into agreements with us that require them to observe our Privacy Policy.</p>
                
                <h5 className="support_heading">No Guarantee of Technical Security over the Internet</h5>
                <p className="support_text">Although we employ technical security measures to protect the confidentiality of your personal information, no transmission over the Internet can be guaranteed to be completely secured. Therefore, MARREN, its subsidiaries, affiliates, third party service providers and their respective directors, officers, employees and agents do not represent, warrant or guarantee that personal information will be protected against misuse, loss or alterations and do not accept any liability for personal information submitted to them nor for your or third parties’ use or misuse of personal information. Further, it is your responsibility to safeguard the confidentiality of your passwords that allow you access to secured areas of Proxfit App.</p>
                <p className="support_text">BY PROVIDING US WITH YOUR PERSONAL INFORMATION, WE WILL ASSUME THAT YOU HAVE CONSENTED TO OUR COLLECTION, USE AND DISCLOSURE OF THAT PERSONAL INFORMATION FOR THE PURPOSE SPECIFIED, OR THE PURPOSE DESCRIBED, IN THIS DISCLOSURE, AT THE TIME OF COLLECTION.</p>
    
                <h5 className="support_heading">ACCEPTANCE OF OUR PRIVACY POLICY AND DISCLOSURE:</h5>
                <p className="support_text">BY USING PROXFIT APP AND SUBMITTING YOUR INFORMATION, YOU SIGNIFY YOUR ACCEPTANCE OF OUR PRIVACY POLICY AND DISCLOSURE; AND YOU ADHERE TO THE TERMS OF USE POSTED ON PROXFIT APP.</p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Legal;