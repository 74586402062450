const WikiSupportFeatures = [
  {
    featureId: 0,
    heading: "a. Creating Account",
    name: "creating-account",
    text: "You have just installed Proxfit application on Google Play or Apple Store, so now it's time to create an account. Choose Sign Up option on our Welcome to Proxfit screen. Please read Terms Of Use carefully and if you agree with everything written, press Agree and Continue button at the bottom of the page. You will be taken to the first step of account creation, where you need to choose a country code and your phone number to register on our platform. Tap the Continue button. You will receive a message with a four-digit code. Please use this code in  the next step. If you entered a wrong phone number, just press on a Wrong number link and you will be taken back to the previous step. If you did not receive a message with a code for verifying your phone number, please choose an option Resend SMS, which will be active 20 seconds after sending a message for verification.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580291621/proxfitWeb/proxfitImages/supportPage/lyi513on8wsghyaphrm7.png",
        imgAlt: "Proxfit Create Account Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580291622/proxfitWeb/proxfitImages/supportPage/ylcxgqvodvgbbexkythe.png",
        imgAlt: "Proxfit Create Account Screen 2"
      },
      {
        imgID: 2,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580291621/proxfitWeb/proxfitImages/supportPage/eagtsreduwljj10mmrti.png",
        imgAlt: "Proxfit Create Account Screen 3"
      },
      {
        imgID: 3,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580291622/proxfitWeb/proxfitImages/supportPage/qazkrej3nfkp4rw4hmrl.png",
        imgAlt: "Proxfit Create Account Screen 4"
      }
    ]
  },
  {
    featureId: 1,
    heading: "",
    name: "",
    text: "In the next Sign Up step, you need to enter your Username and Password. To create an account, tap on Continue button and continue by selecting an account type. If you want to finish the creation of the account later, just Log In with credentials for your created account.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580292833/proxfitWeb/proxfitImages/supportPage/yce262sq6u3cbkwwklz0.png",
        imgAlt: "Proxfit Create Account Screen 5"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580292833/proxfitWeb/proxfitImages/supportPage/aiuj7li1kmgfftfsjkjt.png",
        imgAlt: "Proxfit Create Account Screen 6"
      }
    ]
  },
  {
    featureId: 2,
    heading: "",
    name: "",
    text: "Sign up as a regular user, choosing User on a Select Account Type step. As a regular user, you will be able to post media that will be visible to other users on Proxfit social network. You will be able to interact with other users on Proxfit by commenting and liking other people’s post or by chatting with someone. You can search and explore services (business type accounts) like gyms, spas, supplement stores and personal trainers in your proximity and buy discount vouchers. In the last step of Sign Up process, enter your personal information, save them and enjoy Proxfit.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635215/proxfitWeb/proxfitImages/supportPage/pkedrzh1apshyct7df47.jpg",
        imgAlt: "Proxfit Create Account Screen 7"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635237/proxfitWeb/proxfitImages/supportPage/vl6zav0dg0onjv88z1sk.jpg",
        imgAlt: "Proxfit Create Account Screen 8"
      }
    ]
  },
  {
    featureId: 3,
    heading: "",
    name: "",
    text: "If you have business related to one or more categories we are offering in the app (gym, spa, personal trainer or supplements), choose a Business type of account. Having a business account you will be able to do everything a regular user can, and even more. Your service will be visible on a map in our app to all users of Proxfit that are close to the address of your service. You can create vouchers and sell them to users of Proxfit app. In the last step of the Sign Up process, please add all details about your service so your future clients can be introduced to your business. You can edit your profile information on your profile page later by choosing Edit Profile option. To edit your business name or category you will have to contact our support.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635216/proxfitWeb/proxfitImages/supportPage/fl8q4x4bct2uogogduib.jpg",
        imgAlt: "Proxfit Create Account Screen 9"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635220/proxfitWeb/proxfitImages/supportPage/q8zfcinpzthobgetrb5x.jpg",
        imgAlt: "Proxfit Create Account Screen 10"
      }
    ]
  },
  {
    featureId: 4,
    heading: "b. Creating Post",
    name: "creating-post",
    text: "Create a Post visible to every user that follows you on Proxfit. Share your memories and experiences in a few easy steps. Go to your profile page and tap on the orange plus button in the bottom right corner of the page. This will take you to a New Post template, where you can upload images and videos directly from your gallery or by taking photo or video. Add a Title and Description for it. You can also Tag People that follow you and they will receive a notification that you tagged them on some post, making sure they will see it, like it and comment on it.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635353/proxfitWeb/proxfitImages/supportPage/ni2au30ipbz2zouj7ohf.jpg",
        imgAlt: "Proxfit Create Post Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635351/proxfitWeb/proxfitImages/supportPage/a8tbmfkgon29prt70scr.jpg",
        imgAlt: "Proxfit Create Post Screen 2"
      },
      {
        imgID: 2,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635355/proxfitWeb/proxfitImages/supportPage/abanhirjilozm2erqzeo.jpg",
        imgAlt: "Proxfit Create Post Screen 3"
      }
    ]
  },
  {
    featureId: 5,
    heading: "c. Adding Likes and Comments",
    name: "likes-comments",
    text: "Add a Like or a Comment on someone's post by tapping on a Like or Comment icon. Every time you do this, the owner receives notification about it. If you want to see other Comments or list of users who liked that post, tap on a word Comments or Likes. Use Reply option to reply on a certain comment. Delete your own comments or replies by swiping to the left. ",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580293726/proxfitWeb/proxfitImages/supportPage/qppjmb4aovfkxmtqaxwo.png",
        imgAlt: "Proxfit Comments Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/v1580293715/proxfitWeb/proxfitImages/supportPage/rtodalrnekpxmoz8fvgj.png",
        imgAlt: "Proxfit Comments Screen 2"
      },
      {
        imgID: 2,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580293357/proxfitWeb/proxfitImages/supportPage/lio9lasqyj3ofsjwbjdy.png",
        imgAlt: "Proxfit Comments Screen 3"
      },
      {
        imgID: 3,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580293358/proxfitWeb/proxfitImages/supportPage/plweau80o3st1fsyqdfv.png",
        imgAlt: "Proxfit Comments Screen 4"
      }
    ]
  },
  {
    featureId: 6,
    heading: "d. Find a Service provider in your proximity",
    name: "finding-services",
    text: "Allow Proxfit to see your current location and find service providers in your proximity on the Explore tab. There are four categories of services, Gym, Spa, Personal trainer, and Supplements. One service provider can offer more than one type of service, for example, Gym and Supplements. By tapping on a service pin on map, you will see a Description box with the information about that Service provider, such as the kind of service he/she is offering, the address, his/her rating mark and if you tap on a Description box you will be taken to his/her profile page where you can find even more information. To help you find a Service provider, please use filters for categories and distance. For example, if you want to find a gym which also sells supplements within the radius of 5 km of your current location, you will need to select gym and supplements filter and 5 km filter for distance. You can also search services by their proxfit name or username, or even hashtags if they used them while writing their business description, using a Search tool on top of this page. Good luck in finding the best service provider!",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635364/proxfitWeb/proxfitImages/supportPage/v76rfpiudsgwiupynkhh.jpg",
        imgAlt: "Proxfit Explore Screen"
      }
    ]
  },
  {
    featureId: 7,
    heading: "e. Edit your Profile",
    name: "edit-profile",
    text: "You entered wrong information while creating your account or some of them have changed in the meantime? No worries! You can always edit your profile information, just tap on the Edit Profile button on your profile page. Change your profile photo or description every time you feel like doing it. If you have business account, you can also change your address and working hours. But remember, to change your business name or category, you will have to contact our support. ",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580294004/proxfitWeb/proxfitImages/supportPage/maroimzmfftfbuk699fq.jpg",
        imgAlt: "Proxfit Edit Profile Screen"
      }
    ]
  },
  {
    featureId: 8,
    heading: "f. Change your Phone Number",
    name: "change-phone-number",
    text: "Your account is connected to a certain phone number, the one you registered with while Signing up. One phone number can be used for only one account on Proxfit. You can change the phone number  connected to your account by going on the Settings menu and choosing Change Phone Number option. Choose country code and enter new Phone Number. As you go further, you will receive a message with a four-digit code on the new phone number you just entered. Please enter this code and verify your new phone number.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580294647/proxfitWeb/proxfitImages/supportPage/fl8zvvv6scknehxe02gb.jpg",
        imgAlt: "Proxfit Change Phone Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580294647/proxfitWeb/proxfitImages/supportPage/pdh11o0grpcnspsc7by3.jpg",
        imgAlt: "Proxfit Change Phone Screen 2"
      }
    ]
  },
  {
    featureId: 9,
    heading: "g. Change your Password",
    name: "change-password",
    text: "To change login password go to the Settings menu on your profile page and choose Change Password option. In the first input field enter the old password, in the second new password and in the third input field repeat the new password. Next time you login in Proxfit, use the new password you just created.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635371/proxfitWeb/proxfitImages/supportPage/hl7dnpyl5p4hhcjbxlux.jpg",
        imgAlt: "Proxfit Change Password Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635374/proxfitWeb/proxfitImages/supportPage/daexkq17jrzhukpjmpls.jpg",
        imgAlt: "Proxfit Change Password Screen 2"
      }
    ]
  },
  {
    featureId: 10,
    heading: "h. Notifications Settings",
    name: "notifications-settings",
    text: "Push notifications for certain notification types can easily be turned off. If you want to turn off push notifications for Likes, Comments, Follows, Tags, Reviews, Chat and Vouchers, go to the Settings menu and choose the Notification Settings option and switch off any notification you would like. Don't forget to tap on a Save button.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635368/proxfitWeb/proxfitImages/supportPage/x0w8yrkdekwmn0mm53qs.jpg",
        imgAlt: "Proxfit Notifications Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635370/proxfitWeb/proxfitImages/supportPage/b7bt3queqp5tyrqel0e0.jpg",
        imgAlt: "Proxfit Notifications Screen 2"
      }
    ]
  },
  {
    featureId: 11,
    heading: "i. Voucher Creation",
    name: "create-voucher",
    text: "If you have Business account, you have an opportunity to create a Voucher, which users can buy through the Proxfit App using Credit cards (Visa, MasteCrad and JCB) or Debit cards. Before you do this, please go to your Settings menu on your profile page and choose Bank Account, where you need to enter and save your bank account and contact details. This needs to be done to ensure that you get your money at the end of a Payment Cycle, for all vouchers you sold and provided services. After you save these details, you can expect a call from our Proxfit team. We want to make sure that all the details are correct, and after you confirm them, our team will make sure that these details are now verifed.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580295438/proxfitWeb/proxfitImages/supportPage/khpnxe69ixy67hhneemv.png",
        imgAlt: "Proxfit Settings Menu"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580295437/proxfitWeb/proxfitImages/supportPage/wr4yk1b2kyok51btavxa.png",
        imgAlt: "Proxfit Bank Account Details Screen"
      }
    ]
  },
  {
    featureId: 12,
    heading: "",
    name: "",
    text: "After successful verification, simply press on the orange Plus button on your profile page, and choose New Voucher option. You will see that you can create a voucher using our Voucher Template, and by uploading the image for it, and filling in all the necessary fields, you will be on your way to sell it and earn money. You can use Voucher Preview option before you publish your voucher. Once you have published voucher you are not going to be able to edit it within the app, but you can contact our Technical Support if you need any further changes for your published vouchers. Published vouchers can be listed in one of the four groups of vouchers, Active, Sold-out, Unlisted and Cancelled. Active list contains all your vouchers that are available to users for purchase. Once all vouchers of one kind are sold, this voucher will be listed in the Sold-out list. Unlisted list of vouchers is a list of vouchers expired by Listing End Date and Cancelled list is a list of vouchers you cancelled (later in the text).",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580296491/proxfitWeb/proxfitImages/supportPage/ao0unm1whhh7kvgofxrx.png",
        imgAlt: "Proxfit Voucher Creation Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580296488/proxfitWeb/proxfitImages/supportPage/ypiogmagketskrskmrpt.png",
        imgAlt: "Proxfit Voucher Creation Screen 2"
      },
      {
        imgID: 2,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580296489/proxfitWeb/proxfitImages/supportPage/xzqpfa5d2x1nuitwjfy6.png",
        imgAlt: "Proxfit Voucher Creation Screen 3"
      }
    ]
  },
  {
    featureId: 13,
    heading: "",
    name: "",
    text: "Every published voucher has additional options you can find after you’ve tapped on the button in the top right corner of your published voucher. These options are Purchases, where you can keep track of every voucher purchase or usage and Cancel if you want to cancel the voucher. By cancelling your voucher, you will cancel all vouchers that are left in stock, but all purchased vouchers are valid for users to use them. List of purchases in Purchases page for a certain voucher is visible for every voucher, whether it is active, sold-out, unlisted or cancelled.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580297241/proxfitWeb/proxfitImages/supportPage/c9gakrkgc8d8zwkhq10t.png",
        imgAlt: "Proxfit Voucher Options"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580297242/proxfitWeb/proxfitImages/supportPage/sxxbnwsddpejtwcn5azz.png",
        imgAlt: "Proxfit Voucher Cancelling"
      },
      {
        imgID: 2,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635244/proxfitWeb/proxfitImages/supportPage/c5bk4ceoavnopg8zntwf.jpg",
        imgAlt: "Proxfit Voucher Purchases Screen"
      }
    ]
  },
  {
    featureId: 14,
    heading: "j. Buying Voucher",
    name: "buy-voucher",
    text: "Proxfit App allows you to buy Vouchers, whether you are regular or business user. Get special offers from service providers, who are offering discounts on their services, while selling vouchers within the app. You can purchase any available Voucher using your Debit or Credit card. Just simply go to the profile of the service provider that you have found on Explore map, or your friend told you about, and visit his Voucher tab. By tapping on the Voucher you liked you will find more details about it. From there you can choose the number of vouchers you wish to buy and just tap on the Buy button to make a purchase. You will be taken to a Choose a Payment Method screen, where you can choose either to pay with Credit card or Debit card. For debit cards payments we are using third-party payment processor for credit cards payments. After you have done your payment, you will receive notification about the purchase, and you will see your purchased voucher it the list of Active Purchased Vouchers. In case you had a problem of any kind while purchasing, please contact our Technical Support. Beside the Active list of vouchers, list of vouchers that are available for usage, you can see two additional tabs for Used and Expired vouchers. After you use a voucher, it will be listed in the Used list. The Expired list is for vouchers you did not use and they expired by Usage End Date. Please be aware that every purchased voucher has its end date for usage.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580298264/proxfitWeb/proxfitImages/supportPage/hiwamh8axvbvrvokrx1n.png",
        imgAlt: "Proxfit Voucher Buying Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580298265/proxfitWeb/proxfitImages/supportPage/wt2baqzejyw3um63qylg.png",
        imgAlt: "Proxfit Voucher Buying Screen 2"
      },
      {
        imgID: 2,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580298267/proxfitWeb/proxfitImages/supportPage/yp7mc2byc2xtbcouokhk.png",
        imgAlt: "Proxfit Voucher Buying Screen 3"
      },
      {
        imgID: 3,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580298273/proxfitWeb/proxfitImages/supportPage/ddwd8j0mxjbc8r3ztb2v.png",
        imgAlt: "Proxfit Voucher Buying Screen 4"
      }
    ]
  },
  {
    featureId: 15,
    heading: "k. Using Voucher",
    name: "use-voucher",
    text: "You successfully purchased a Voucher and now it's time to use it! First, you need to contact service provider from who you bought the voucher. You can go to his/her profile and message him/her by tapping on a Chat button. It is up to you and service provider to make an agreement about the meeting session when you will use your Voucher for services which service provider gives through that voucher. Your purchased voucher has a unique QR code, which you need to show to service provider. To open a QR code, press on the QR code icon in the top right corner of the voucher. Service provider will scan that code, and you are ready to go and enjoy! Scanning of a voucher means the voucher is used, and you can track all your used vouchers in the Used vouchers list. Also, after using a Voucher you are able to give a rate and review of the service provider who sold you the voucher and provided services for it by visiting his profile again and by tapping on a green circle with a rate number.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580303681/proxfitWeb/proxfitImages/supportPage/gpdzpvifijgon1pryon6.png",
        imgAlt: "Proxfit Voucher Usage 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580303663/proxfitWeb/proxfitImages/supportPage/mxbfwdwmbh7zujd8p6su.png",
        imgAlt: "Proxfit Voucher Usage 2"
      }
    ]
  },
  {
    featureId: 16,
    heading: "l. Voucher Gift",
    name: "voucher-gift",
    text: "It is always a perfect time to surprise your friends with a gift. You can send your purchased voucher as a gift to other users of Proxfit. On your voucher, you will find a Gift button. Just tap on this button to open a Search option where you can search for users by their name or username. Tap on the user you wish to send a gift and tap on a Send button in the confirmation dialog. Someone is lucky for having you as a friend.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580304233/proxfitWeb/proxfitImages/supportPage/k263fxczwcpqysi4cxbh.png",
        imgAlt: "Proxfit Voucher Gift"
      }
    ]
  },
  {
    featureId: 17,
    heading: "m. Scanning Voucher",
    name: "scan-voucher",
    text: "Your customer came to you and he/she wants to use his/hers voucher. He/she will show you the unique QR code for the voucher he/she wants to use. You need to activate your QR code scanner. Just tap on the QR code scanner icon in the top right corner of your profile page, and place your mobile device above the customer's mobile device so you can scan the code. Once you scan the voucher, that voucher is considered used. You have permission to scan only vouchers you have created, and you cannot scan the same voucher more than once. After successful scanning, you will see a picture of a voucher you just scanned as well as the name of the user who brought you that voucher.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635253/proxfitWeb/proxfitImages/supportPage/a1rvmxxiauwxuhzo77h1.jpg",
        imgAlt: "Proxfit Voucher Scanning 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635262/proxfitWeb/proxfitImages/supportPage/bpo7uke1a1pdilkjslvf.jpg",
        imgAlt: "Proxfit Voucher Scanning 2"
      }
    ]
  },
  {
    featureId: 18,
    heading: "n. Chat",
    name: "chat",
    text: "If you want to send someone a message in Proxfit visit his/her profile and tap on a Chat button to open a chat dialog with him/her. All the conversations you had are listed in the Chat tab in the main menu. To Edit or Delete a message just use a long press on that message and the popup with all additional options will show up. You can also create a Group chat. In the Chat tab, you will find a Group Chat button, tap on it to find users you wish to add in your group for chatting. In every conversation you had, individual or group, there is an additional Info option in the right top corner. Open this info screen to find more about the group you are joined or to use additional options that are available there.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580305489/proxfitWeb/proxfitImages/supportPage/qc9lvhk4uwvyrfgsuqck.png",
        imgAlt: "Proxfit Chat Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580305930/proxfitWeb/proxfitImages/supportPage/lqk2xfeh5d4sgwm9eyrs.png",
        imgAlt: "Proxfit Chat Screen 2"
      },
      {
        imgID: 2,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580305493/proxfitWeb/proxfitImages/supportPage/jw4r85lvifkkbmievybs.png",
        imgAlt: "Proxfit Chat Screen 3"
      },
      {
        imgID: 3,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580305483/proxfitWeb/proxfitImages/supportPage/hxofappvqxzbsd9liq3k.png",
        imgAlt: "Proxfit Chat Screen 4"
      }
    ]
  },
  {
    featureId: 19,
    heading: "o. Reviewing Services",
    name: "review-service",
    text: "Give a Rate and a Review to a service provider. You can only rate and review services who provided their services to you for the purchased vouchers. To do this, go to the profile of the service provider you want to review and tap on a green circle with a rate number. You will be taken to the Rate and Review page of that service, where you can see other people’s reviews and rates. Tap on a plus button on the top right corner of this page to open a Rate and Review popup. Write your experience and impressions and tap on a star to give a rate.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635379/proxfitWeb/proxfitImages/supportPage/thnw01j4oyyoggqkmlnk.jpg",
        imgAlt: "Proxfit Reviewing Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635382/proxfitWeb/proxfitImages/supportPage/kvbhb9pk4diuxtoemdwf.jpg",
        imgAlt: "Proxfit Reviewing Screen 2"
      },
      {
        imgID: 2,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635384/proxfitWeb/proxfitImages/supportPage/l8pxhyxdq9aabovlszri.jpg",
        imgAlt: "Proxfit Reviewing Screen 3"
      },
      {
        imgID: 3,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635389/proxfitWeb/proxfitImages/supportPage/uxbt8zss25xiios0vxmr.jpg",
        imgAlt: "Proxfit Reviewing Screen 4"
      }
    ]
  },
  {
    featureId: 20,
    heading: "p. Payment Cycles",
    name: "payment-cycle",
    text: "You sold vouchers through Proxfit App and provided services to the clients who brought vouchers to you after scanning a unique voucher QR code. Now you need to wait to end the current Payment Cycle, and you will get the money you have earned. Payment Cycles last 14 days, and payments are done right after the end, on the 15th day from the beginning of the Payment Cycle. The money will be transferred to your bank account, the one you entered in the Bank Account tab in the Settings menu of your profile. You can see all the details about the current Payment Cycle on a Sales tab in the Setting menu. There, you can see a list of Delivered and Pending Vouchers, as well as the amount of money that will be available to you at the end of that Payment Cycle or the amount that is still pending.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580306569/proxfitWeb/proxfitImages/supportPage/aifw4omjzgqitsphlsk0.png",
        imgAlt: "Proxfit Sale Leadger Screen 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1580306580/proxfitWeb/proxfitImages/supportPage/pjqipmehyitplqxkoyqs.png",
        imgAlt: "Proxfit Sale Leadger Screen 2"
      }
    ]
  },
  {
    featureId: 21,
    heading: "q. Sponsored Posts",
    name: "sponsored-posts",
    text: "Promote your services and products through Posts which will be visible to everyone who is using Proxfit App on the Stream tab. In order to do this, please fill out our Commercial Enquiries form on this website and we will get back to you very soon with all the necessary info on how to make your Post Sponsored.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561649026/proxfitWeb/proxfitImages/supportPage/ls2oz5qqakfikobmxpuf.png",
        imgAlt: "Proxfit Sponsored Post 1"
      },
      {
        imgID: 1,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635430/proxfitWeb/proxfitImages/supportPage/ggflfs3t4xcw0ztrzn3a.png",
        imgAlt: "Proxfit Sponsored Post 2"
      }
    ]
  },
  {
    featureId: 22,
    heading: "r. Ads",
    name: "campaign",
    text: "Besides promoting your business through sponsored posts, we also offer space for advertising your vouchers on our Ad splash screen. This screen is visible every time users start the Proxfit App, and after tapping on Check It Out button, they will be redirected to your Vouchers tab on your profile. If you want to use this special feature, please fill out our Commercial Enquiries form on this website asking us how you can lease this place for advertising and we will get back to you as soon as possible.",
    images: [
      {
        imgID: 0,
        imgSrc: "https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635299/proxfitWeb/proxfitImages/supportPage/xzbinv1djpsrlugqo18e.jpg",
        imgAlt: "Proxfit Campaign Splash Screen"
      }
    ]
  }
]

export default WikiSupportFeatures;