import React, { Component } from 'react';
import './home.css';
import ReactWOW from 'react-wow';

class Home extends Component {

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.props.isVisible && this.props.history.location.hash !== "#home") {
      this.props.history.replace("#home")
    } 
  };

  render() {
    return(
      <section id="home">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="home-content">
                  <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12">
                      <div className="home-content-left">
                        <h1>Find The Best Service Offers for Fitness and Health in Your Proximity</h1>
                        <p>The Media platform that combines Fitness, Lifestyle and Social Networking</p>
                        <div className="download-app-content">
                          <ReactWOW animation='fadeIn' duration='0.5s' delay='0.2s'>
                            <a className="single-download" href="https://play.google.com/store/apps/details?id=com.proxfit" target="_blank">
                              <div className="download-icon">
                                <img src={require("./../../assets/images/icons/google-play-icon.png")} alt="google play icon"/>
                              </div>
                              <div className="download-content">
                                <p>GET IT ON</p>
                                <span>Google Play</span>
                              </div>
                            </a>
                          </ReactWOW>
                          <ReactWOW animation='fadeInLeft'>
                            <a className="single-download" href="https://apps.apple.com/hr/app/proxfit/id1439232267"  target="_blank">
                              <div className="download-icon">
                                <img src={require("./../../assets/images/icons/apple-icon.png")} alt="apple store icon"/>
                              </div>
                              <div className="download-content">
                                <p>Download on the</p>
                                <span>App Store</span>
                              </div>
                            </a>
                          </ReactWOW>
                        </div>
                      </div>
                      </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="home-content-right">
                        <img className="img-middle" src="https://res.cloudinary.com/ds7i0533q/image/upload/q_auto/v1561635025/proxfitWeb/proxfitImages/homeSection/w60hqhdnq0szuayw3ru2.png" alt="Fitness Services on Map"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  
};

export default Home;