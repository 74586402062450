import React, { Component } from 'react';
import API from '../../API.js';


class ContactSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      accountType: "User",
      issue: "User Acconut Creation",
      email: "",
      phoneNum: "",
      message: "",
      emailTwo: 'mymail@email.com',
      contactTwo: "",
      warnigMessage: "",
      redWarningLetters: false,
      loaderSendingMsgVisibe: false, 
      messageInRed: true,
      messageInGreen: false
    }

  }

  onChangeInputHandler = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  usernameValidation = (username) => {
    let regex = /^[a-z0-9.\-_]{3,16}$/;
    return regex.test(username);
  };

  validateEmail = (email) => {
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  handleSubmitContactSupport = (e) => {
    this.setState({warnigMessage: ""})
    e.preventDefault();
    if (!this.state.username || !this.state.email || !this.state.message || !this.state.phoneNum) {
      this.setState({redWarningLetters: true, warnigMessage: "All fields with an *  must be filled out."})
      return
    } else if (this.state.username.length > 16 || this.state.username.length < 3) {
      this.setState({warnigMessage: "Username must be from 3 to 16 characters." })
      return true
    } else if (!this.usernameValidation(this.state.username)) {
      this.setState({warnigMessage: "Username is not valid. Acceptable only a-z 0-9 .-_"})
      return
    } else if (!this.validateEmail(this.state.email)) {
    this.setState({warnigMessage: "Please enter a valid email format."})
      return
    }
    this.setState({warnigMessage: "", loaderSendingMsgVisibe: true})

    const emailObj = {
      username: this.state.username,
      email: this.state.email,
      message: this.state.message,
      phone: this.state.phoneNum,
      issue: this.state.issue,
      accountType: this.state.accountType,
      contact_two: this.state.contactTwo,
      email_two: this.state.emailTwo
    }

    API.sendSupportEmail(emailObj) 
    .then(res => {
        this.setState({
          messageInRed: false,
          messageInGreen: true,
          loaderSendingMsgVisibe: false, 
          warnigMessage: "Thank you! Your message has been sent, we will contact you soon!",
          fullName: "",
          email: "",
          phoneNum: "",
          message: "",
          redWarningLetters: false
        }, () => setTimeout(() => 
        this.setState({messageInGreen: false, messageInRed: true, warnigMessage: ''}), 5000));
    })
    .catch(error => {
      this.setState({messageInRed: true, loaderSendingMsgVisibe: false, warnigMessage: 'Oops! An error occured and your message could not be sent.'})
    });
  }


  render(){
    return(
      <div id="contact-support" className="contact-support-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact_support_area">
                <div className="title-area second-title">
                  <h2 className="title">Technical Support</h2>
                  <p>If you inquire any technical issue please fill out this form and our technical team will try to solve your problem and get back to you shortly.</p>
                </div>
                <form action="" className="contact_support_form">
                  <div className="input-wrap">                  
                    <input id="help-username" type="text" className={!this.state.redWarningLetters ? "custom-input" : "custom-input fill-fields"} placeholder="Username *"
                      name="username" value={this.state.username} onChange={this.onChangeInputHandler} />
                  </div>
                  <div className="input-wrap"> 
                    <span>Account Type:</span>                 
                    <select id="help-account" className='selectable_dropdown' value={this.state.accountType} onChange={this.onChangeInputHandler}>
                      <option value="user">User</option>
                      <option value="serivce">Service</option>
                    </select>
                  </div>
                  <div className="input-wrap"> 
                      <span>Choose Issue:</span>                 
                      <select id="help-issue" className='selectable_dropdown' value={this.state.issue} onChange={this.onChangeInputHandler}>
                        <option value="User Acconut Creationn">User Acconut Creation</option>
                        <option value="Service Acconut Creation">Service Acconut Creation</option>
                        <option value="Posting Media">Posting Media</option>
                        <option value="Chatting">Chatting</option>
                        <option value="Voucher Creation">Voucher Creation</option>
                        <option value="Buying Voucher">Buying Voucher</option>
                        <option value="Using Voucher">Using Voucher</option>
                        <option value="Scanning Voucher">Scanning Voucher</option>
                        <option value="Payment Cycless">Payment Cycles</option>
                        <option value="Other">Other</option>
                      </select>
                  </div>
                  <div className="input-wrap">                  
                    <input id="help-email" type="email" className={!this.state.redWarningLetters ? "custom-input" : "custom-input fill-fields"}  placeholder="Email Address *"
                      name="email" value={this.state.email} onChange={this.onChangeInputHandler} />
                  </div>
                  <div className="input-wrap">                  
                      <input id="help-phone" type="tel" className={!this.state.redWarningLetters ? "custom-input" : "custom-input fill-fields"} placeholder="Phone Number *" 
                        name="phoneNum" value={this.state.phoneNum} onChange={this.onChangeInputHandler}/>
                    </div>
                  <div className="input-wrap">                  
                    <textarea id="help-message" className={!this.state.redWarningLetters ? "custom-textarea" : "custom-textarea fill-fields"} rows="3" placeholder="Message *"
                      name="message" value={this.state.message} onChange={this.onChangeInputHandler} ></textarea>
                  </div>
                  <div className="input-field">
                    <input type="text" name="contact" defaultValue={this.state.contactTwo} />
                  </div>
                  <div className="text-field">
                    <input type="text" name="email" defaultValue={this.state.emailTwo} />
                  </div>
                  <div className="send-area">
                    <button id="send-support" className="send-btn send-btn-support" type="submit"  onClick={this.handleSubmitContactSupport} 
                      disabled={this.state.loaderSendingMsgVisibe ? true : false}>
                      <span className={this.state.loaderSendingMsgVisibe ? "hidden" : ""}>Send</span>
                    </button>
                    <div className={this.state.loaderSendingMsgVisibe ? "loader_wrap": "hidden"} >
                      <div className="start-loader"></div>
                    </div>
                  </div>  
                </form>
                <div className={this.state.messageInGreen ? "form-messages success" : (this.state.messageInRed ? "form-messages error" : "form-messages") }>{this.state.warnigMessage}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactSupport;